import { render, staticRenderFns } from "./alert.vue?vue&type=template&id=4016829e&scoped=true"
import script from "./alert.vue?vue&type=script&lang=js"
export * from "./alert.vue?vue&type=script&lang=js"
import style0 from "./alert.vue?vue&type=style&index=0&id=4016829e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4016829e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
